// extracted by mini-css-extract-plugin
export var anchor = "styles-module--anchor--ClnCL";
export var bullet = "styles-module--bullet--f5oAG";
export var bulletTitle = "styles-module--bulletTitle--p1f9y";
export var equipment = "styles-module--equipment--yPzMA";
export var item = "styles-module--item--gbK1I";
export var itemBullets = "styles-module--itemBullets--kC58B";
export var itemDescription = "styles-module--itemDescription--d5JEd";
export var itemTitle = "styles-module--itemTitle--Z46Yy";
export var list = "styles-module--list--BUIAW";
export var title = "styles-module--title--tXNO+";
export var titleLogo = "styles-module--titleLogo--veoXw";